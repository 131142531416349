import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { SiGoogledrive } from 'react-icons/si';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { FaCheckDouble, FaCircle, FaClipboard, FaCloudDownloadAlt, FaPowerOff, FaRProject, FaRegCircle, FaRegEye, FaRegHeart, FaShoppingBag, FaUser, FaWindowClose } from 'react-icons/fa';

import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const EmployeeData = () => {

  const websitePrefix = 'www.themavennest.shop';

  const { id, title } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();

    // const loadAds = () => {
    //   if (window.adsbygoogle) {
    //     window.adsbygoogle.push({});
    //   } else {
    //     setTimeout(loadAds, 100);
    //   }
    // };

    // const script = document.createElement('script');
    // script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    // script.crossOrigin = 'anonymous';
    // script.async = true;
    // script.onload = loadAds;

    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };

    window.scrollTo(0, 0); // Scroll to top when component mounts


  }, []);

  const [cvc, setCVC] = useState('');  // description


  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://themavennest.shop/database/get-product.php?id=${encodeURIComponent(id)}`
      );
      const json = await response.json();
      if (Object.keys(json).length === 0) {

      }
      else {

        setData(json);
        setCVC(json[0].details)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getFileIcon = (driveLink) => {
    const fileExtension = driveLink.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      return <SiGoogledrive className="w-6 h-6 text-red-500" />;
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
    } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      return <SiGoogledrive className="w-6 h-6 text-green-500" />;
    } else {
      return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
    }
  };





  const handleShareClick = () => {

    const linkToCopy = window.location.href;

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        console.log('Link copied to clipboard:', linkToCopy);
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaClipboard className="mr-2 text-green-600" />
            <span>Link copied to clipboard</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );
      })
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
        toast.error('Error copying link to clipboard');
      });
  };



  const updateCart = async (id) => {

    if (localStorage.getItem(`${websitePrefix}-publiclogin`) === '1') {

        const userid = localStorage.getItem(`${websitePrefix}-publicid`);

        try {
            const formData = new FormData();
            formData.append('userid', userid);
            formData.append('productid', id);
            console.log("text " + id + " 2nd " + userid);

            const response = await fetch('https://themavennest.shop/database/add-to-cart.php', {
                method: 'POST',
                body: formData,
            });

            const json = await response.json();

            if (json.success === true) {
                // Added successfully

                toast(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaCheckDouble className="mr-2 text-green-600" />
                        <span>Added to Cart</span>
                    </div>,
                    {
                        className: 'custom-toast-success', // Add a custom CSS class for styling
                    }
                );


            } else {
                // Failed to add

                toast(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaWindowClose className="mr-2 text-red-600" />
                        <span>could not Add</span>
                    </div>,
                    {
                        className: 'custom-toast-success', // Add a custom CSS class for styling
                    }
                );

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }
    else {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaWindowClose className="mr-2 text-red-600" />
                <span>Please login first</span>
            </div>,
            {
                className: 'custom-toast-success', // Add a custom CSS class for styling
            }
        );
    }


};



  function AddtoCart(id) {

    updateCart(id);
}

  return (
    <div className="bg-white">

      <Header />





      <Helmet>
        <title>{data[0]?.title || ''}</title>
        <meta name="description" content={data[0]?.description.replace(/(<([^>]+)>)/gi, '').replace(/\\/g, '') || ''} />
        <link
          rel="canonical"
          href={`https://gigadevden.com/projects/${data[0]?.id || ''}/${data[0]?.title?.toLowerCase()?.replace(/[^a-zA-Z0-9\s]/g, ' ').trim().replace(/\s+/g, '-') || ''}`}
        />
        <meta name="robots" content="index,follow" />
      </Helmet>


      

      

      <section class="text-gray-700 body-font overflow-hidden bg-white">
  <div class="container px-5 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-col">
    <div className="flex flex-wrap">
    {data[0]?.image ? (
                                       <img alt="ecommerce" class="lg:w-1/3 w-full object-cover object-center rounded border border-gray-200" src={`https://themavennest.shop${data[0].image}`}/>

                                  
                                ) : (

                                  <img alt="ecommerce" class="animate-pulse lg:w-1/2 w-full object-cover object-center rounded border border-gray-200" src="https://www.clker.com/cliparts/b/m/L/p/r/u/gray-square-hi.png"/>


                                )}
      <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 class="text-sm title-font text-gray-500 tracking-widest pb-1">
        {data[0]?.tag ? (
                                 <span>{data[0].tag}</span>
                                  
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>

                                )}
        </h2>
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">
        {data[0]?.title ? (
                                 <span>{data[0].title}</span>
                                  
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>

                                )}
        </h1>
        <div class="flex mb-4">
          <span class="flex items-center">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <span class="text-gray-600 ml-3">Top Rated on</span>
          </span>
          <span class="flex ml-3 pl-3 py-2 border-l-2 border-gray-200">
            <a class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="ml-2 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a class="ml-2 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
            </a>
          </span>
        </div>

       
        <p class="leading-relaxed">
        {data[0]?.description ? (
                                 <span>{data[0].description}</span>
                                  
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>

                                )}
        </p>

        <p className="leading-relaxed text-lg pt-12 font-bold text-gray-600">
    {data[0]?.quantity !== undefined ? (
        data[0].quantity > 0 ? (
            <span>
                QTY Available: <span className="font-normal">{data[0].quantity}</span>
            </span>
        ) : (
            <span>Sold Out</span>
        )
    ) : (
        <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>
    )}
</p>

        <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
          <div class="flex">
            <button class="border-2 border-gray-300 rounded-full w-6 h-6 focus:outline-none"></button>
            <button class="border-2 border-gray-300 ml-1 bg-gray-700 rounded-full w-6 h-6 focus:outline-none"></button>
            <button class="border-2 border-gray-300 ml-1 bg-red-500 rounded-full w-6 h-6 focus:outline-none"></button>
          </div>
          <div class="flex ml-6 items-center">
          
            <div class="relative">
              <p class="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-500 text-base pl-3 pr-10">
                <span>
                {data[0]?.category ? (
                                 <span>Qamson</span>
                                  
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>

                                )}
        
                </span>
                
              </p>
              <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="flex">
          <span class="title-font font-medium text-2xl text-gray-900">
          {data[0]?.price ? (
                                 <span>Rs. {data[0].price}</span>
                                  
                                ) : (

                                    <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>

                                )}
          </span>
          <button onClick={() => AddtoCart(data[0].id)} class="flex ml-auto text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded">Add to Cart
          <FaShoppingBag className="items-center pt-0.5 text-xl ml-2"/>
          </button>
           
      
        </div>
      </div>

      </div>
      
      <div className="mt-6 mb-20">
      <p class="text-gray-900 text-xl title-font font-medium mb-1 mt-4 pb-4 border-b border-gray-700 w-1/3">
        Details
      </p>

                                  <ReactQuill
                                      value={cvc}
                                      //value={ReactHtmlParser(cvc.replace(/\\/g, ''), { transform })}
                                      readOnly={true} // Set readOnly prop to true
                                      modules={{
                                          toolbar: false // Disable the toolbar
                                      }}
                                  />
                              </div>



    </div>

    
  </div>

  
</section>




      <Footer />

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

    </div>
  );
};

export default EmployeeData;
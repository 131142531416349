import React, { useEffect, useState } from 'react'
import Header from '../partials/Header';
import Footer from '../partials/Footer';

import eventPoster1 from '../images/event1.png'
import eventPoster2 from '../images/event2.png'
import eventPoster3 from '../images/event3.png'

import { Link } from 'react-router-dom'

import mainPoster from '../images/main-poster.png'
import forSmall from '../images/small-poster.png'


import watch from '../images/watch.png'
import shoes from '../images/shoes.png'
import { FaCheckDouble, FaShoppingBag, FaWindowClose } from 'react-icons/fa';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Ethnic from '../images/ethnic.png'
import Bridal from '../images/bridal.png'

import card1 from '../images/card-1.png'
import card2 from '../images/card-2.png'
import card3 from '../images/card-3.png'

const ProductCard = ({ category, name, price, bgColor, image, priceTextColor }) => (
  <div className="flex w-[300px] h-[360px]">


    <div className={`flex-shrink-0 my-6 relative ${bgColor} rounded-lg max-w-xs shadow-lg`}>
      <svg
        className="absolute bottom-0 left-0 mb-8"
        viewBox="0 0 375 283"
        fill="none"
        style={{ transform: 'scale(1.5)', opacity: 0.1 }}
      >
        <rect
          x="159.52"
          y="175"
          width="152"
          height="152"
          rx="8"
          transform="rotate(-45 159.52 175)"
          fill="white"
        />
        <rect
          y="107.48"
          width="152"
          height="152"
          rx="8"
          transform="rotate(-45 0 107.48)"
          fill="white"
        />
      </svg>
      <div className="relative pt-10 px-10 flex items-center justify-center">
        <div
          className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
          style={{
            background: 'radial-gradient(black, transparent 60%)',
            transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)',
            opacity: 0.2,
          }}
        ></div>
        <img className="relative w-40" src={image} alt={name} />
      </div>
      <div className="relative text-white px-6 pb-6 mt-6">
        <span className="block opacity-75 -mb-1">{category}</span>
        <div className="flex justify-between">
          <span className="block font-semibold text-xl">{name}</span>
          <span className={`block bg-white rounded-full ${priceTextColor} text-xs font-bold px-3 py-2 leading-none flex items-center`}>
            {price}
          </span>
        </div>
      </div>
    </div>
  </div>
);

const ProductCard1 = ({ category, name, price, bgColor, image, priceTextColor }) => (
  <div className={`flex-shrink-0 my-6 relative ${bgColor} rounded-lg max-w-md shadow-lg`}>
    <svg
      className="absolute bottom-0 left-0 mb-8"
      viewBox="0 0 375 283"
      fill="none"
      style={{ transform: 'scale(1.5)', opacity: 0.1 }}
    >
      <rect
        x="159.52"
        y="175"
        width="152"
        height="152"
        rx="8"
        transform="rotate(-45 159.52 175)"
        fill="white"
      />
      <rect
        y="107.48"
        width="152"
        height="152"
        rx="8"
        transform="rotate(-45 0 107.48)"
        fill="white"
      />
    </svg>
    <div className="relative p-3 flex items-center justify-center">
      <div
        className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
        style={{
          background: 'radial-gradient(black, transparent 60%)',
          transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)',
          opacity: 0.2,
        }}
      ></div>
      <img className="relative w-60 pt-2 px-2" src={image} alt={name} />
    </div>
    <span className="px-[136px] h-[1px]"></span>
    <div className="relative text-white px-4 pb-6">
      <span className="block opacity-75 -mb-1">{category}</span>
      <div className="flex justify-between">
        <span className="block font-semibold text-xl">{name}</span>
        <span className={`block bg-white rounded-full ${priceTextColor} text-xs font-bold px-3 py-2 leading-none flex items-center`}>
          {price}
        </span>
      </div>
    </div>
  </div>
);


function Home() {


  const imageText = [
    'Stop',
    '50% OFF',
    'Win It',
    // Add more text values as needed
  ];

  const imageDate = [
    '26',
    '15',
    '4',
    // Add more text values as needed
  ];

  const imageDescription = [
    'Wear a Healthy Fashion by Qamson Sports.',
    'Get 50% Discount on Mens Attire!',
    'Shop Best Quality product only on Qamson Sports.',
    // Add more text values as needed
  ];



  const images = [
    eventPoster1,
    eventPoster2,
    eventPoster3,
    // Add more image URLs as needed
  ];



  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {

    topSales();
    newArrivals();

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the interval time as desired (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const [showModal, setShowModal] = useState(true);




  function openProductsPage() {
    window.location.href = '#/products';
  }






  const [loading, setLoading] = useState(true);

  const [data1, setData1] = useState([]);

  const topSales = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://themavennest.shop/database/homepage-products.php?category=${encodeURIComponent('Transknit Attire')}&filter=${encodeURIComponent('Top Sales')}`
      );
      const json = await response.json();

      console.log(json);

      if (json.data.length !== 0) {
        setData1(json.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const [data2, setData2] = useState([]);

  const newArrivals = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://themavennest.shop/database/homepage-products.php?category=${encodeURIComponent('Transknit Attire')}&filter=${encodeURIComponent('Newest Arrivals')}`
      );
      const json = await response.json();

      console.log(json);

      if (json.data.length !== 0) {
        setData2(json.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };



  function setCurrentProjectId(id, title) {
    const hash = window.location.hash;
    const formattedTitle = title
      .toLowerCase() // Convert title to lowercase
      .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
      .trim()
      .replace(/\s+/g, '-'); // Replace spaces with dashes
    window.location.href = '#/products/' + id + '/' + formattedTitle;
  }



  const websitePrefix = 'www.themavennest.shop';


  const updateCart = async (id) => {

    if (localStorage.getItem(`${websitePrefix}-publiclogin`) === '1') {

      const userid = localStorage.getItem(`${websitePrefix}-publicid`);

      try {
        const formData = new FormData();
        formData.append('userid', userid);
        formData.append('productid', id);
        console.log("text " + id + " 2nd " + userid);

        const response = await fetch('https://themavennest.shop/database/add-to-cart.php', {
          method: 'POST',
          body: formData,
        });

        const json = await response.json();

        if (json.success === true) {
          // Added successfully

          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaCheckDouble className="mr-2 text-green-600" />
              <span>Added to Cart</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );


        } else {
          // Failed to add

          toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaWindowClose className="mr-2 text-red-600" />
              <span>could not Add</span>
            </div>,
            {
              className: 'custom-toast-success', // Add a custom CSS class for styling
            }
          );

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    }
    else {
      toast(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaWindowClose className="mr-2 text-red-600" />
          <span>Please login first</span>
        </div>,
        {
          className: 'custom-toast-success', // Add a custom CSS class for styling
        }
      );
    }


  };



  function AddtoCart(id) {

    updateCart(id);
  }




  return (


    <div>
      <Header />

      <div className="mx-4 md:mx-16 flex flex-row space-x-4 h-[450px] md:h-[750px] md:mb-16">





        <div className="w-full xl:w-2/3 h-full">
          {/* <img src={background} className='w-full p-16 rounded-lg opacity-30'/> */}

          <div className="relative h-full md:h-2/3 w-full z-30 p-2">
            {/* <div className='absolute w-full h-full bg-black rounded-2xl opacity-60' >
    </div> */}
            <div className='absolute px-4 md:px-16 lg:mr-12 md:mt-16' >


              <div class="row py-8">
                <div class="col-xl-4 col-lg-5 col-sm-7 mx-lg-0 mx-auto">
                  <div class="border-radius-xl mb-3 position-relative">
                    <span class="mask bg-dark border-radius-xl z-index-1 opacity-5"></span>
                    <div class="d-flex align-items-center z-index-2 position-relative">
                      <div class="text-gray-900 icon-move-right text-sm ms-2 w-100 d-flex align-items-center">
                        <Link to='/products'>
                          <div className="flex flex-row">
                            <span class="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">new</span>
                            <span className="ml-2">Summer Collection</span>
                          </div>
                        </Link>
                        {/* <i class="fas fa-chevron-right text-xs ms-auto me-2" aria-hidden="true"></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1 class="sm:mt-16 md:mt-0 mt-40 hidden sm:block sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-orange-500 via-gray-700 to-gray-700" 
              >Wear It! <br class="lg:block hidden" />
                {/* <span class="relative text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-red-500" style={{ WebkitTextStroke: '1px black' }}>Order now</span> */}
                <span class="relative text-transparent" style={{ WebkitTextStroke: '1px black' }}>Order now</span>
                .</h1>

              {/* <h1 className="sm:mx-auto sm:w-10/12 font-black text-2xl text-center sm:text-5xl md:text-4xl lg:w-auto lg:text-left lg:text-5xl text-white mt-40 sm:mt-0">Creative Market, develop<br className="lg:block hidden" /> <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">with the community</span>.</h1> */}
              <div className="hidden sm:block relative mt-4 space-y-8 lg:w-11/12">
                {/* <p className="sm:text-md text-gray-200 ">
          Embark on a rewarding development journey and turn your ideas into something extraordinary. Share your creations with a vibrant community, forge valuable connections, and unlock earning potential. Start developing with passion, purpose, and the opportunity to thrive in a supportive environment.
        </p> */}
                {/* <span className="block font-semibold text-gray-400">The best companion platform for your articles.</span> */}


                <div className="grid grid-cols-2 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start mt-8">
                  <Link aria-label="Become a dev" to="/products" className="p-4 border bg-gray-800 bg-opacity-50 border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 hover:border-cyan-300/30">
                    <div className="flex justify-center md:space-x-4">
                      {/* <b className="text-sky-500">&lt;/&gt;</b> */}
                      {/* <img className="w-6 h-6 hidden md:block" src="https://cdn-icons-png.flaticon.com/512/458/458910.png" alt="slack logo" loading="lazy" width="128" height="128" /> */}
                      <span className="font-medium block text-white">Shop Now!</span>
                    </div>
                  </Link>
                  <Link aria-label="add to chat" to="/cart" className="p-4 border-2 bg-gray-800 bg-opacity-50 border-gray-700 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20 hover:border-green-300/30">
                    <div className="flex justify-center md:space-x-2">
                      {/* <p className="hidden md:block">🛒</p> */}
                      {/* <img className="w-6 h-6" src="https://cdn0.iconfinder.com/data/icons/leto-blue-project-management/64/_-19-256.png" alt="chat logo" loading="lazy" width="128" height="128" /> */}
                      <span className="font-medium block text-white">Cart</span>
                    </div>
                  </Link>
                  {/* <a aria-label="add to zoom" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-blue-400 hover:shadow-lg hover:shadow-blue-600/20 dark:hover:border-blue-300/30">
  <div className="flex justify-center space-x-4">
    <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/zoom.png" alt="chat logo" loading="lazy" width="128" height="128" />
    <span className="hidden font-medium md:block dark:text-white">Zoom</span>
  </div>
</a> */}
                </div>

                {/* <div className="dark:text-gray-300">
🔥🌟
<span>Other integrations :</span>
<a href="#" className="font-semibold text-gray-700 dark:text-gray-200">Discord,</a>
<a href="#" className="font-semibold text-gray-700 dark:text-gray-200">Telegram</a>
</div> */}

                {/* <div className="pt-12 flex gap-6 lg:gap-12 justify-between grayscale lg:w-2/3">
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/airbnb.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/ge.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/coty.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/transferwise.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
</div> */}
              </div>



              <div>



              </div>


            </div>

            {/* 
    {!imageLoaded ? (
      // <div className="absolute inset-0 flex items-center justify-center bg-gray-200 animate-pulse">
<div></div>
      // </div>
    ) : (

    <img
      className={`w-full h-full rounded-2xl object-cover`}
      src={mainPoster}
      alt="Main Poster"
      onLoad={handleImageLoad}
    />

      )} */}





            <div className="absolute bottom-10 left-10 mt-2 mr-2 h-6 flex items-center justify-center opacity-70 sm:hidden">
              <Link to='/products' className="text-lg font-bold text-white">

                <span className="bg-red-500 text-white rounded-md w-auto p-2 shadow-md">SHOP</span>

              </Link>
            </div>

            <img class="hidden sm:block shadow-[0px_0px_15px_rgba(0,0,0,0.1)] w-full h-full rounded-2xl object-cover" src={mainPoster} loading="lazy" />



            <Link to='/products'>
              <img class="block sm:hidden  shadow-[0px_0px_15px_rgba(0,0,0,0.1)] w-full h-full rounded-2xl object-cover" src={forSmall} loading="lazy" />
            </Link>





          </div>



          <div className="flex md:flex-row md:h-1/3 w-full">
            {/* <img class="bg-gray-200 mt-2 w-1/2 pr-2 rounded-2xl object-cover" src={downPoster1} loading="lazy" /> */}

            {/* <div class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10"> */}
            <div class=" flex max-h-full w-full sm:rounded-2xl bg-white">

              <div className="flex flex-row w-full">






                {/* //left space */}




                <div className="h-[400px] w-full  hidden md:flex">
                  <div id="journal-scroll" className="flex-row overflow-y-auto whitespace-nowrap items-center justify-between flex space-x-0">
                    <Link to='/products'>
                      <ProductCard
                        category="Qamson"
                        name="Gloves"
                        price="BUY"
                        bgColor="bg-orange-500"
                        image={card1}
                        priceTextColor="text-orange-500"
                      />
                    </Link>
                    <Link to='/products'>
                      <ProductCard
                        category="Qamson"
                        name="Helmets"
                        price="BUY"
                        bgColor="bg-teal-500"
                        image={card2}
                        priceTextColor="text-teal-500"
                      />
                    </Link>
                    <Link to='/products'>
                      <ProductCard
                        category="Transknit"
                        name="Pads"
                        price="BUY"
                        bgColor="bg-purple-500"
                        image={card3}
                        priceTextColor="text-purple-500"
                      />
                    </Link>


                  </div>


                </div>


                {/* <div class="flex-col justify-center mt-20 font-montserrat hidden md:flex">
	<div
		class="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-[0px_0px_15px_rgba(0,0,0,0.1)] p-3 max-w-xs md:max-w-4xl mx-auto border border-white bg-white">
		<div class="w-full md:w-1/3 bg-white grid place-items-center">
			<img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" class="rounded-xl" />
    </div>
			<div class="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
				<div class="flex justify-between item-center">
					<p class="text-gray-500 font-medium hidden md:block">Vacations</p>
					<div class="flex items-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-500" viewBox="0 0 20 20"
							fill="currentColor">
							<path
								d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						</svg>
						<p class="text-gray-600 font-bold text-sm ml-1">
							4.96
							<span class="text-gray-500 font-normal">(76 reviews)</span>
						</p>
					</div>
					<div class="">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-pink-500" viewBox="0 0 20 20"
							fill="currentColor">
							<path fill-rule="evenodd"
								d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
								clip-rule="evenodd" />
						</svg>
					</div>
					<div class="bg-gray-200 px-3 py-1 rounded-full text-xs font-medium text-gray-800 hidden md:block">
						Superhost</div>
				</div>
				<h3 class="font-black text-gray-600 md:text-xl text-md">The Majestic and Wonderful Bahamas</h3>
				<p class="md:text-md text-gray-500 text-sm">The best kept secret of The Bahamas is the country’s sheer
					size and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
				<p class="text-xl font-black text-gray-800">
					$110
					<span class="font-normal text-gray-600 text-base">/night</span>
				</p>
			</div>
		</div>
	</div> */}


                {/* <div class="w-1/2">
          <div class="m-8 my-20 max-w-[400px] mx-auto">
            <div class="mb-8">
              <h1 class="mb-4 text-3xl font-extrabold text-gray-900 font-montserrat">Significant Articles</h1>
              <p class="text-gray-600">Get the most out of GIGaDevDen by staying up to date with what's happening.</p>
            </div>
            <div class="space-y-4">
              <button onClick={() => window.location.href="/infodexeus/articles"} class="p-3 bg-black rounded-full text-white w-full font-semibold">Read Articles</button>
              <button onClick={() => window.location.href="console-home"} class="p-3 bg-white border rounded-full w-full font-semibold">Publish</button>
            </div>
          </div>
        </div> */}



                {/* <div class='w-1/2 flex items-center justify-center px-2'>
          <div class='w-full max-w-md  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden'>
            <div class='max-w-md mx-auto'>
              <div class='h-[236px]' style={{
                backgroundImage: 'url(https://img.freepik.com/free-photo/pasta-spaghetti-with-shrimps-sauce_1220-5072.jpg?w=2000&t=st=1678041911~exp=1678042511~hmac=e4aa55e70f8c231d4d23832a611004f86eeb3b6ca067b3fa0c374ac78fe7aba6)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
              >
              </div>
              <div class='p-4 sm:p-6'>
                <p class='font-bold text-gray-700 text-[22px] leading-7 mb-1'>GameDen Android Studio Base App</p>
                <div class='flex flex-row'>
                  <p class='text-[#3C3C4399] text-[17px] mr-2 line-through'>$ 154</p>
                  <p class='text-[17px] font-bold text-[#0FB478]'>$ 5</p>
                </div>
                <p class='text-[#7C7C80] font-[15px] mt-6'>Android stucio project for sale (Java): MySQL Database</p>


                <a target='_blank' href='' class='block mt-10 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-[#FFC933] rounded-[14px] hover:bg-[#FFC933DD] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                  View Project
                </a>
                <a target='_blank' href="/" class='block mt-1.5 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform rounded-[14px] hover:bg-[#F2ECE7] hover:text-[#000000dd] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                  Download
                </a>
              </div>
            </div>
          </div>
        </div> */}




              </div>

            </div>
            {/* </div> */}

            {/* <div className="relative mt-2 w-1/2 pl-2 z-30">

      <div className='absolute px-4' >
      <div class="row bg-black bg-opacity-50 rounded-xl">
        <div class="col-xl-4 col-lg-5 col-sm-7 mx-lg-0 mx-auto">
          <div class="border-radius-xl p-1 mb-3 position-relative">
            <span class="mask bg-dark border-radius-xl z-index-1 opacity-5"></span>
            <div class="d-flex align-items-center z-index-2 position-relative">
              <a href="#soft-ui-dashboard-tailwind-builder" class="text-white icon-move-right text-sm ms-2 w-100 d-flex align-items-center">
                <div className="flex flex-row">
                  <span class="ml-2 rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">new</span>
                  <span className="ml-2 mr-2 font-urbanist font-bold">GAMEDEN</span>
                </div>
                <i class="fas fa-chevron-right text-xs ms-auto me-2" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>


      <img class="bg-gray-200 rounded-2xl object-cover" src="https://get.wallhere.com/photo/1920x1080-px-board-games-digital-art-logo-TV-657115.jpg" loading="lazy" />

    </div> */}


          </div>

        </div>

        <div className="hidden xl:w-1/3 xl:flex xl:flex-col h-full">
          {/* <img src={background} className='w-full p-16 rounded-lg opacity-30'/> */}

          <div class="rounded-2xl">
            <div class="group relative">
              {/* <img class="w-full object-cover block rounded-2xl" src={eventPoster} alt="" /> */}





              <div className="relative rounded-2xl overflow-hidden">
                <div
                  className="flex transition-transform duration-1000 ease-in-out"
                  style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                  }}
                >
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className={`p-2 w-full h-auto transition-transform duration-1000 ${index === currentIndex ? 'scale-100' : 'scale-50'
                        }`}
                      style={{
                        flex: '0 0 100%',
                      }}
                    >
                      <img
                        src={image}
                        loading="lazy"
                        alt={`Carousel Slide ${index}`}
                        className="shadow-[0px_0px_15px_rgba(0,0,0,0.1)] w-full h-auto object-cover rounded-2xl"

                      //style={{ borderRadius: '1rem' }}

                      />
                      <div class="p-5">
                        <div className="flex flex-row bg-gray-950 w-fit bg-opacity-25 px-1 py-1 rounded-full">
                          <span class="hidden rounded-full bg-sky-500 px-1 py-1 h-6 w-6 text-xs text-white sm:flex justify-center items-center">{imageDate[index]}</span>
                          <span className="mx-2">{imageText[index]}</span>
                        </div>
                        <p className="pt-2 text-sm">{imageDescription[index]}</p>
                        {/* <h3 class="text-gray-950 text-xl font-urbanist font-bold">International Dog day <span className="text-gray-400 font-normal text-sm">26 Aug, 2023</span> </h3>
        <h3 class="text-white text-lg">Epoch <span className="text-gray-400">Tycho</span> </h3>
        <h3 class="text-white text-lg">Epoch <span className="text-gray-400">Tycho</span> </h3> */}

                        {/* <p class="text-gray-400">Tycho</p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              {/* <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly rounded-t-2xl">
        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
            <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
          </svg>
        </button>

        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
          </svg>
        </button>

        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg>
        </button>
      </div> */}
            </div>





          </div>


          {/* <img class="h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/3790191.jpg" />
  <img class="mt-2 h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/4422239.jpg" /> */}
          {/* <img class="mt-2 h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/4422576.jpg" /> */}

        </div>

      </div>








      <style>{`
@import url('//fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url(//pro.fontawesome.com/releases/v5.10.0/css/all.css);
@import url(//cdn.jsdelivr.net/npm/cryptocoins-icons@2.9.0/webfont/cryptocoins.css);
body {
font-family: Poppins, sans-serif;
}
.rounded-4xl {
border-radius: 3.5rem;
}
.phone-top {
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
width: 50%;
height: 3rem;
z-index: 1;
}
.phone-top-inner {
position: relative;
height: 2rem;
width: 100%;
background-color: #fff;
border-bottom-left-radius: 1.5rem;
border-bottom-right-radius: 1.5rem;
}
.phone-top-inner:before {
content: "";
position: absolute;
top: 0;
left: -1rem;
height: 1rem;
width: 1rem;
border-top-right-radius: 50%;
box-shadow: 0.5rem -0.5rem 0 0 #fff;
}
.phone-top-inner:after {
content: "";
position: absolute;
top: 0;
right: -1rem;
height: 1rem;
width: 1rem;
border-top-left-radius: 50%;
box-shadow: -0.5rem -0.5rem 0 0 #fff;
}
`}
      </style>



      <style>
        {`
                body {
                    font-family : Urbanist, sans-serif;
                    @apply bg-gray-900
                }
        
                .navbar-active .hamburger div:first-child {
                    @apply rotate-45 translate-y-1.5;
                }
                .navbar-active .hamburger div:last-child {
                    @apply -rotate-45 -translate-y-1;
                }
                .navbar-active div:first-child div:first-child div:last-child{
                    @apply block lg:flex
                }
                `}
      </style>




      {/* 
          <div class="absolute -top-40 lg:w-2/3 w-full h-80 -z-10">
        <svg xmlns='http://www.w3.org/2000/svg' class="drop-shadow-[-20px_10px_0px_#ffc949]" viewBox='0 0 2000 2000'
            fill='#ffb14a'>
            <path
                d='M994 112c-703-2-920.47 400.35-904 905 13.35 409 32.03 946.66 977 861 684-62 792-279 835-777 61.67-714.25-288.33-987.24-908-989Z'>
            </path>
        </svg>
    </div> */}





      <div className="px-6 md:hidden">
        <div id="journal-scroll" className="flex flex-row overflow-x-auto space-x-4 items-center">
        <Link to='/products'>
                      <ProductCard1
                        category="Qamson"
                        name="Gloves"
                        price="BUY"
                        bgColor="bg-orange-500"
                        image={card1}
                        priceTextColor="text-orange-500"
                      />
                    </Link>
                    <Link to='/products'>
                      <ProductCard1
                        category="Qamson"
                        name="Helmets"
                        price="BUY"
                        bgColor="bg-teal-500"
                        image={card2}
                        priceTextColor="text-teal-500"
                      />
                    </Link>
                    <Link to='/products'>
                      <ProductCard1
                        category="Transknit"
                        name="Pads"
                        price="BUY"
                        bgColor="bg-purple-500"
                        image={card3}
                        priceTextColor="text-purple-500"
                      />
                    </Link>

        </div>
      </div>





      <style>
        {`
  #journal-scroll {
    
    overflow-y: auto;
  }

  #journal-scroll::-webkit-scrollbar {
    height: 0px;
    width: 16px;
  }

  #journal-scroll::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 41px;
  }

  #journal-scroll:hover::-webkit-scrollbar-thumb {
    background: ; /* Change the scrollbar color to red on hover */
  }

  #journal-scroll::-webkit-scrollbar-track {
    background: transparent;
  }
`}
      </style>


      <div class="2xl:container 2xl:mx-auto mx-2">


        <div class="lg:px-20 md:px-6 pl-4 md:pb-12 md:pt-28 py-10">
          <div className="flex flex-row justify-start md:justify-between space-x-4 items-end">
            <h1 class="text-xl lg:text-2xl font-bold text-gray-800 dark:text-white text-center dark:text-gray-50">Best Sellers</h1>
            <Link to='/products'>
              <h2 class="text-sm lg:text-md text-gray-800 dark:text-white text-center dark:text-gray-50 underline">View All</h2>
            </Link>
          </div>
          <div class="grid gap-6 lg:gap-8 mt-8 md:mt-10">





            <div id="journal-scroll" className="text-sm scrollable-container flex items-center space-x-4 overflow-y-auto">






              {data1.map((item, index) => (
                <button onClick={() => setCurrentProjectId(item.id, item.title)} key={index}>
                  <div className="bg-gray-50 dark:bg-gray-800 px-4 pt-2 pb-8 relative font-montserrat">
                    <div className="flex justify-center items-center mt-4">
                      <div className="w-60 h-60 overflow-hidden rounded-lg relative">
                        <img
                          className="object-cover w-full h-full"
                          src={`https://themavennest.shop${item.image}`}
                          alt={item.title}
                          role="img"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col w-60 p-1 mt-4">
                      <h3 className="text-md font-semibold mb-2 text-left">{item.title}</h3>
                      <p className="text-gray-700 text-xs break-words mb-4 text-left">
                        {item.description}
                      </p>
                      <div className="flex items-center">
                        <span className="text-xl font-bold text-gray-700">RS. {item.price}</span>
                        <div className="ml-4 flex items-center justify-center opacity-70">
                          <a className="text-xs font-bold text-white">
                            {item?.quantity !== undefined ? (
                              item.quantity > 0 ? (
                                <span>

                                </span>
                              ) : (
                                <span className="bg-red-500 text-white rounded-md w-auto p-2">Sold Out</span>
                              )
                            ) : (
                              <div></div>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end items-center space-x-2 mt-4">
                      <button aria-label="show in red color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" strokeWidth="0.5" />
                        </svg>
                      </button>
                      <button aria-label="show in white color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" strokeWidth="0.5" />
                        </svg>
                      </button>
                      <button aria-label="show in black color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" strokeWidth="0.5" />
                        </svg>
                      </button>
                    </div>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        // Call the separate function here
                        AddtoCart(item.id);
                      }}
                      className="absolute top-2 right-2 bg-white p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
                      aria-label="Add to cart"
                    >
                      <FaShoppingBag className="text-lg text-pink-500" />
                    </button>
                  </div>
                </button>
              ))}











            </div>


          </div>


        </div>
      </div>





      <div class="2xl:container 2xl:mx-auto mx-2">


        <div class="lg:px-20 md:px-6 pl-4 md:pb-12 md:pt-10 py-10">
          <div className="flex flex-row justify-start md:justify-between space-x-4 items-end">
            <h1 class="text-xl lg:text-2xl font-bold text-gray-800 dark:text-white text-center dark:text-gray-50">New Arrivals</h1>
            <Link to='/products'>
              <h2 class="text-sm lg:text-md text-gray-800 dark:text-white text-center dark:text-gray-50 underline">View All</h2>
            </Link>
          </div>
          <div class="grid gap-6 lg:gap-8 mt-8 md:mt-10">




            <div id="journal-scroll" className="text-sm scrollable-container flex items-center space-x-4 overflow-y-auto">



              {data2.map((item, index) => (
                <button onClick={() => setCurrentProjectId(item.id, item.title)} key={index}>
                  <div className="bg-gray-50 dark:bg-gray-800 px-4 pt-2 pb-8 relative font-montserrat">
                    <div className="flex justify-center items-center mt-4">
                      <div className="w-60 h-60 overflow-hidden rounded-lg relative">
                        <img
                          className="object-cover w-full h-full"
                          src={`https://themavennest.shop${item.image}`}
                          alt={item.title}
                          role="img"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col w-60 p-1 mt-4">
                      <h3 className="text-md font-semibold mb-2 text-left">{item.title}</h3>
                      <p className="text-gray-700 text-xs break-words mb-4 text-left">
                        {item.description}
                      </p>
                      <div className="flex items-center">
                        <span className="text-xl font-bold text-gray-700">RS. {item.price}</span>
                        <div className="ml-4 flex items-center justify-center opacity-70">
                          <a className="text-xs font-bold text-white">
                            {item?.quantity !== undefined ? (
                              item.quantity > 0 ? (
                                <span>

                                </span>
                              ) : (
                                <span className="bg-red-500 text-white rounded-md w-auto p-2">Sold Out</span>
                              )
                            ) : (
                              <div></div>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end items-center space-x-2 mt-4">
                      <button aria-label="show in red color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" strokeWidth="0.5" />
                        </svg>
                      </button>
                      <button aria-label="show in white color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" strokeWidth="0.5" />
                        </svg>
                      </button>
                      <button aria-label="show in black color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" strokeWidth="0.5" />
                        </svg>
                      </button>
                    </div>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        // Call the separate function here
                        AddtoCart(item.id);
                      }}
                      className="absolute top-2 right-2 bg-white p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
                      aria-label="Add to cart"
                    >
                      <FaShoppingBag className="text-lg text-pink-500" />
                    </button>



                  </div>
                </button>
              ))}



            </div>


          </div>


        </div>
      </div>












      <div class="flex justify-center items-center px-2 md:20 lg:px-32">
        <div class="2xl:mx-auto 2xl:container py-12 px-4 sm:px-6 xl:px-20 2xl:px-0 w-full">
          <div class="flex flex-col jusitfy-center items-center space-y-10">
            <div class="flex flex-col justify-center items-center ">
              <h1 class="text-3xl xl:text-4xl font-semibold leading-7 xl:leading-9 text-gray-800 dark:text-white">Everything You Need</h1>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4 md:gap-x-8 w-full">
              <div class="relative group flex justify-center items-center h-full w-full">
                <img class="object-center object-cover h-full w-full" src="https://valuebox.pk/cdn/shop/products/e8YQUh4vnc.jpg?v=1718105630" alt="girl-image" />
                <button onClick={() => openProductsPage()} class="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Jersey</button>
                <div class="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
              </div>

              <div class="flex flex-col space-y-4 md:space-y-8 mt-4 md:mt-0">
                <div class="relative group flex justify-center items-center h-full w-full">
                  <img class="object-center object-cover h-full w-full" src="https://trex.com.pk/uploads/trex/cuetoT9WGctUhMSqV5PR2ENRRH4G0L4up8wVBnVT.jpg" alt="shoe-image" />
                  <button onClick={() => openProductsPage()} class="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Shoes</button>
                  <div class="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
                </div>
                <div class="relative group flex justify-center items-center h-full w-full">
                  <img class="object-center object-cover h-full w-full" src="https://m.media-amazon.com/images/I/61S6hra8VvL.jpg" alt="watch-image" />
                  <button onClick={() => openProductsPage()} class="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Gloves</button>
                  <div class="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
                </div>
              </div>

              <div class="relative group justify-center items-center h-full w-full hidden lg:flex">
                <img class="object-center object-cover h-full w-full" src="https://tornadosportscompany.com/cdn/shop/products/BLACK_PADS_1.jpg?v=1671609129&width=533" alt="girl-image" />
                <button onClick={() => openProductsPage()} class="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Pads</button>
                <div class="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
              </div>
              <div class="relative group flex justify-center items-center h-full w-full mt-4 md:hidden md:mt-8 lg:hidden">
                <img class="object-center object-cover h-full w-full hidden md:block" src="https://tornadosportscompany.com/cdn/shop/products/BLACK_PADS_1.jpg?v=1671609129&width=533" alt="girl-image" />
                <img class="object-center object-cover h-full w-full md:hidden" src="https://tornadosportscompany.com/cdn/shop/products/BLACK_PADS_1.jpg?v=1671609129&width=533" alt="olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2" />
                <button onClick={() => openProductsPage()} class="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Pads</button>
                <div class="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
              </div>
            </div>
            <div class="relative group hidden md:flex justify-center items-center h-full w-full mt-4 md:mt-8 lg:hidden">
              <img class="object-center object-cover h-full w-full hidden md:block" src="https://tornadosportscompany.com/cdn/shop/products/BLACK_PADS_1.jpg?v=1671609129&width=533" alt="girl-image" />
              <img class="object-center object-cover h-full w-full sm:hidden" src="https://tornadosportscompany.com/cdn/shop/products/BLACK_PADS_1.jpg?v=1671609129&width=533" alt="olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2" />
              <button onClick={() => openProductsPage()} class="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Pads</button>
              <div class="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
            </div>
          </div>
        </div>
      </div>









      <Footer />

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


    </div>

  );
}

export default Home;
